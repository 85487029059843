import styles from '../About/About.module.css'
import Missao from '../../Image/missao.png'
import Valores from '../../Image/valoresicon.png'
import Brain from '../../Image/brain.png'

function About() {
    return (
        <article>
            <div className={styles.missao}>
                <div className={styles.marca}>
                    <img src={Missao} alt="" />
                </div>
                <div className={styles.introducao}>
                    <span className={styles.icones}><img src={Brain} alt="Icone de Serviços" /></span>
                    <h2>Nossa Missão</h2>
                    <h5>Levar inspiração e inovação a todos os surfistas do mundo. Estamos empenhados em fortalecer continuamente a nossa marca e produtos para melhorar nossa posição competitiva.</h5>
                </div>
            </div>
            <div className={styles.valores}>
                <div className={styles.introducao}>
                    <span className={styles.icones}><img src={Valores} alt="Icone de Serviços" /></span>
                    <h2>Nossos Valores</h2>
                    <h5>
                    A <span className={styles.destaque}>HAOLE</span>, se baseia em três conceitos para seu grande sucesso. A metodologia DFP se consiste em:
                    </h5>
                </div>
                <div className={styles.cards}>
                </div>
            </div>
        </article>
    )
}

export default About;