import styles from '../Parcerias/Parcerias.module.css'
import Cartoes from '../../Image/card.svg'
import Parceria from '../../Image/parcerias.png'



function Parcerias() {
    return (
        <aside>
            <div className={styles.introducao}>
                <span className={styles.icones}><img src={Parceria} alt="Icone de Serviços" /></span>
                <h2>Parcerias</h2>
            </div>
            <div className={styles.parcerias}>
                <div className={styles.cartoes}>
                    <img src={Cartoes} alt="cartões" />
                </div>
                <div className={styles.acordeon}>
                    <span className={styles.accordionTittle}> LOREM LOREM LOREM</span>
                    <h1>LOREM LOREM LOREM</h1>
                </div>
            </div>
        </aside>
    )
}

export default Parcerias;