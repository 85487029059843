import styles from '../Info/Info.module.css'
import { Link } from 'react-router-dom'
import Servicos from '../../Image/icon.png'
import Build from '../../Image/um.png'
import Compras from '../../Image/dois.png'
import Praias from '../../Image/tres.png'
import Academia from '../../Image/quatro.png'

function Info() {
    return (
        <section>
            <div className={styles.introducao}>
                <span className={styles.icones}><img src={Servicos} alt="Icone de Serviços" /></span>
                <h2>Nossos Serviços</h2>
            </div>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <span className={styles.icons}><img src={Build} alt="Icone de Serviços" /></span>
                    <h3>Faça sua Prancha</h3>
                    <p>Já imaginou pegar aquela onda que você mesmo fez?</p>
                    <Link to="/">
                        <button><span>Saiba mais</span></button>
                    </Link>
                </div>
                <div className={styles.card}>
                    <span className={styles.icons}><img src={Compras} alt="Icone de Serviços" /></span>
                    <h3>Acessórios</h3>
                    <p>O conforto é a verdadeira chave para não tomar aquele caldo.</p>
                    <Link to="/">
                        <button><span>Saiba mais</span></button>
                    </Link>
                </div>
                <div className={styles.card}>
                    <span className={styles.icons}><img src={Praias} alt="Icone de Serviços" /></span>
                    <h3>Melhores Praias</h3>
                    <p>Saiba onde encontrar as melhores ondas!</p>
                    <Link to="/">
                        <button><span>Saiba mais</span></button>
                    </Link>
                </div>
                <div className={styles.card}>
                    <span className={styles.icons}><img src={Academia} alt="Icone de Serviços" /></span>
                    <h3>Academia</h3>
                    <p>Onde todos os atletas se encontram. Venha nos conhecer e fazer uma aula experimental!!</p>
                    <Link to="/">
                        <button><span>Saiba mais</span></button>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Info;