import styles from './App.css';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import Info from './Components/Info/Info'
import About from './Components/About/About'
import Parcerias from './Components/Parcerias/Parcerias'
import Footer from './Components/Footer/Footer'
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <main className={styles.corpo}>
      <Router>
        <Header />
        <Routes>
           <Route path='/' element={<Home />}/> 
           <Route path='/Info' element={<Info />}/>
           <Route path='/About' element={<About />} />
           <Route path='/Parcerias' element={<Parcerias />} />
        </Routes>
        <Footer />
      </Router>
    </ main>
  );
}

export default App;
