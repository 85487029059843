import styles from './Home.module.css'
import Persona from '../../Image/Persona.png'
import Info from '../Info/Info'
import About from '../About/About'
import Parcerias from '../Parcerias/Parcerias'

function Home() {
    return (
        <body>
         <main className={styles.inicio}>
            <div className={styles.texto}>
                <p>loremloremlorem</p>
                <h1>LOREMLOREMLOREML</h1>
                <h3>loremloremloremloremlorem</h3>
            </div>
            <div className={styles.personaimage}>
                <img src={Persona} alt="" />
            </div>
            </main>
            <Info />
            <About/>
            <Parcerias />
        </body>
    )
}

export default Home;