import styles from '../Footer/Footer.module.css'
import { Link } from 'react-router-dom'
import Github from '../../Image/github.png'
import Figma from '../../Image/figma.png'
import Linkedin from '../../Image/linkedin.png'

function Footer() {
    return (
        <footer>
            <div className={styles.waves}>
                <div className={styles.wave}></div>
                <div className={styles.wave2}></div>
                <div className={styles.wave3}></div>
                <div className={styles.wave2}></div>
            </div>
            <div className={styles.fundo}>
            <div className={styles.socialMedias}>
                <a href="http://" target="_blank" rel="noopener noreferrer"><img src={Github} alt="icone do github" /></a>
                <img src={Figma} alt="icone do figma" />
                <img src={Linkedin} alt="icone do linkedin" />
            </div>
            <div className={styles.menu}>
                <Link to="/"><li>Inicio</li></Link>
                <Link to="/Info"><li>Serviços</li></Link>
                <Link to="/About"><li>Sobre nós</li></Link>
                <Link to="/Parcerias"><li>Parceiros</li></Link>
                <Link to="/Creditos"><li>Creditos</li></Link>
            </div>
            </div>
        </footer>
    )
}

export default Footer;